.statisticsContainer {
  width: 100%;
  min-height: 300px;
  margin: 1rem auto;
}

.statisticsBorder {
  margin: 1rem auto 2rem;
  border-left: 3px solid #000;
  border-bottom: 3px solid #000;
  width: 80%;
  min-height: inherit;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 5vw;
  padding-left: 40px;
}